import { useEffect } from "react"

import { navigate } from "~/gatsby/navigate"
import { getRedirectLanguage } from "~/utils/locales"

const TextTheApp = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()

    navigate(`/${urlLang}/app`, { replace: true })
  }, [])

  return null
}

export default TextTheApp
